const Autocompletes = [];

function Autocomplete(autocomplete_elem, search_url, onSelectCallback, resultField, actionList, customItemRender = null, forBasicInfo = false) {
  this.autocomplete_elem = autocomplete_elem
  this.search_url = search_url
  this.onSelectCallback = onSelectCallback
  this.resultField = resultField
  this.actionlist = actionList
  this.customItemRender = customItemRender
  this.forBasicInfo = forBasicInfo
  const resultsCountStatusValue = 'count'
  const minLength = 3
  const _this = this
  function isResultsCountStatusValue(checkValue) {
    return checkValue === resultsCountStatusValue;
  }
  function isActionValue(checkValue){
    let isAction=false;
    $.each(_this.actionlist, function (value, label) {
      if (parseInt(value) === parseInt(checkValue)){
        isAction = true;

        return false;
      }
    });

    return isAction;
  }
  this.autocomplete_elem.autocomplete({
    minLength: 1,
    source: function (request, response) {
      const term = request.term.trim()
      const repo = _this.search_url.split('/').filter(item => item).pop();
      const skills = []
      if (term.length >= minLength) {
        $.ajax({
          method: 'GET',
          url: _this.search_url + term,
          success: function (data) {
            let total = 0;
            let count = 0;
            if (typeof data.total !== 'undefined') {
              total = data.total;
            }
            if (typeof data.items !== 'undefined') {
              data = data.items;
            }
            $.each(data, function (i, item) {
              if (repo === 'conferences') {
                skills.push({ label: item.name, value: item.id, organizer: item.organizer, location: item.location })
              } else {
                skills.push({ label: item.name, value: item.id })
              }
              count++;
            })
            // Removed from new requirement, keep this just in case we want it back again
            // if (total) {
            //   let res = total == 1 ? ' result' : ' results';
            //   skills.push(
            //       {
            //         label: count + ' out of ' + total + res, value: resultsCountStatusValue
            //       }
            //   )
            // }
            if (total === 0 && repo === 'locations') {
              skills.push(
                {
                  label: 'No result, try to change the location', value: resultsCountStatusValue
                }
              )
            }
            $.each(_this.actionlist, function (value, label) {
              skills.push({label: label[0], value: value})
            });
            response(skills)
          }
        })
      } else {
        $.each(_this.actionlist, function (value, label) {
          skills.push({label: label[0], value: value})
        });
        response(skills)
      }
    },
    classes: {
      "ui-autocomplete": "ui-autocomplete-max-height",
    },
    position: {
      my: "center top",
      at: "center bottom",
      collision: "flip"
    },
    focus: function (event, ui) {
      event.preventDefault()
      // TODO: delete if Nicks approves
      // let selectedValue = ui.item.value;
      // if (!isActionValue(selectedValue) && !isResultsCountStatusValue(selectedValue)) {
      //   $(this).val(ui.item.label)
      // } else {
      //   $(this).val("");
      // }
    },
    change: function( event, ui ) {
      // to be used for debugging
      // console.log(_this.autocomplete_elem);
    },
    select: function (event, ui) {
      event.preventDefault()
      let selectedValue = ui.item.value;
      if (isResultsCountStatusValue(selectedValue)){
        return;
      }

      if (!isActionValue(selectedValue)){
        _this.autocomplete_elem.val(ui.item.label)
        _this.autocomplete_elem.attr('entity_id', ui.item.value)
        if (_this.resultField){
          resultField.val(ui.item.value);
        }
        _this.autocomplete_elem.trigger("autocomplete:readonly");
        if (_this.forBasicInfo) {
          resultField.removeClass('institution-id');
          _this.autocomplete_elem.prop('readonly', true);
          _this.autocomplete_elem.removeClass('institution-name');
          $(`.js-affiliations-add`).removeAttr('disabled', 'disabled').removeClass('button--disabled');
        }
      } else {
        let action = _this.actionlist[selectedValue][1];
        let form = event.target.closest("form");
        let closeFormButton = $(form).find(".js-injected-form-cancel");
        if (closeFormButton && _this.actionlist.hasOwnProperty('closeForm')){
          $(closeFormButton).trigger("click");
        }
        const func = window[action];
        if (typeof func === 'function'){
          func();
        }
      }
      if (typeof _this.onSelectCallback === 'function'){
        _this.onSelectCallback(ui);
      }
    },
    open: function(event, ui) {
      let lastItem = $(this).data('uiAutocomplete').menu.element.find('.ui-menu-item').last();
      let lastItemData = lastItem.data('uiAutocompleteItem');
      let lastItemChild = lastItem.find('.ui-menu-item-wrapper');

      if (lastItemData && lastItemData.value == -1) {
        let arrowRight = '<svg class="icon svg--directional--right"><use xlink:href="#svg--directional--right"></use></svg>';
        lastItem.addClass('ui-menu-item-add-institution');
        lastItemChild.append(arrowRight);
      }
    }
  }).autocomplete( "instance" )._renderItem = function( ul, item ) {
    if (typeof _this.customItemRender === 'function') {
      return $("<li>")
        .append(_this.customItemRender(item))
        .appendTo(ul);
    } else {
      return $("<li>")
        .append($("<div>").text(item.label))
        .appendTo(ul);
    }
  };
}
